/* eslint-disable max-len */
export const ENDPOINT = {
  USER_PROFILE:                 '/api/user/profile/',
  USER_RESET_PASSWORD:          '/api/v2/user/password/',

  BUY_SUBSCRIPTION_APP:         '/api/braintree/buy-subscription-app/',
  BUY_SUBSCRIPTION:             '/api/braintree/buy-subscription/',
  UPGRADE_SUBSCRIPTION:         '/api/braintree/upgrade-subscription/',
  COUPON_VALIDATE:              '/api/braintree/coupon/validate/',
  UPDATE_PAYMENT_METHOD:        '/api/braintree/payment-method/',
  BUY_PRODUCT:                  '/api/braintree/purchases/',
  GET_BRAINTREE_TOKEN:          '/api/braintree/get-token/',

  GIFT_CODE_VALIDATE:           '/api/gift/verify/',
  GIFT_CODE_REDEEM:             '/api/gift/redeem/',
  GIFT_PURCHASE:                '/api/gift/purchase/',

  USER_LOGIN:                   '/api/v2/user/login/',
  WAIT_LIST:                    '/api/v2/waitlist/',
  SET_PASSWORD:                 '/api/v2/user/set-password/',

  TEACHER_SIGN_UP:              '/api/school/v2/teachers/',
  KID_PROFILE_CREATE:           '/api/school/v2/users/create/',

  LMS_SUBSCRIPTION:             '/api/lms/subscription/',
  FEEDBACK:                     '/api/feedback/',
  FEEDBACK_V2:                  '/api/v2/feedback/',
  BLOG_LIKE:                    '/api/storytime/like/',
  COMMENT:                      '/api/comment/',
  WORKSHEET_RATING:             '/api/worksheets/rating/',
  QUIZ_RATING:                  '/api/v4/flashes/rating/',
  INVOICE_PAYMENT:              '/api/invoice-payment/',
  GET_KIDS_REPORT_DATA:         (offset) => `/api/v1/study-reports/kids/?tz_offset=${offset}`,
  GET_KID_REPORT_DATA:          (params, kidId) => `/api/v1/study-reports/kids/${kidId}/studying-time-spent/${params}`,
  GET_COURSE_DETAILS:           (courseId) => `/api/study/v3/course-detail/${courseId}/`,

  MARK_AS_DONE:                 (id) => `/api/worksheets/${id}/accomplished/`,
  ADD_TO_FAVORITE:              (id) => `/api/worksheets/${id}/favorite/`,

  CANCEL_SUBSCRIPTION:          (merchant, id) => `/api/${window.merchant}/subscription/${id}/`,
  NOTIFICATION_MARK_AS_READ:    '/api/user-campaign-message/batch-mark-as-read/',
  USER_SUBSCRIBE:               '/api/user/subscribe/',
  WORKSHEETS_PDF_STATUS:        '/api/worksheets/pdf/',
  WORKSHEET_CHECK_STATUS:       '/api/worksheets/pdf/?commit=1',

  // Skilled worksheets
  GET_SKILLED_WORKSHEET:        (id) => `/api/v1/skilled-worksheets/${id}/`,

  // Classroom
  CLASSROOM_JOIN:               (id) => `/api/classrooms/${id}/members/`,
  CLASSROOM_CODE_CHECK:         '/api/classrooms/join/',
  CLASSROOM_CREATE:             '/api/classrooms/',
  CLASSROOM_START:              (id) => `/api/classrooms/${id}/start/`,
  CLASSROOM_UPDATE:             (id) => `/api/classrooms/${id}/`,
  CLASSROOM_ADD_ACTIVITY:       (classroomId) => `/api/classrooms/${classroomId}/activities/`,
  WEBSITE_WORKSHEETS:           '/api/study/v3/website-worksheets/',
  GET_SUBJECTS_FOR_FILTER:      '/api/worksheet/subjects/',
};
/* eslint-enable */

export const URLS = {
  FREE_SIGN_UP:                 '/free-sign-up/',
  FREE_SIGN_UP_WORKSHEET:       '/free-sign-up/welcome/',
  SIGN_UP:                      '/sign-up/',
  SIGN_UP_TEACHER:              '/sign-up/teacher/',
  DISTRIBUTOR_CODE_SUCCESS:     '/activate-license/success/',
  PARENT_SUBSCRIPTION_SUCCESS:  'subscription-success',
  PARENT_SUBSCRIPTION_PLANS:    'subscription-plans',
  HYBRID_LEARNING_PLATFORM:     'hybrid-learning-platform',
  SIGN_UP_PARENT_COMPLETE:      'sign-up-parent',
  SIGN_UP_PARENT_SUCCESS:       '/sign-up/parent/success/',
  KID_PROFILE_CREATE:           '/kid-profile/create/',
  GIFT_REDEEM_SUCCESS:          '/gift/redeem/success/',
  LEARNING_MATERIALS:           '/learning-materials/',
  GIFT_PURCHASE:                'gift-purchase',
  TG_ONLINE:                    '/tg/',
  CLASSROOM:                    '/classroom',
  WORKSHEET_CHECK_BASE:         '/worksheets-check',
  WORKSHEET_CHECK:              slug => `/worksheets-check/${slug}/`,
  QUIZ_CHECK:                   slug => `/quiz-check/${slug}/`
};

export const GET_PARAMS = {
  // REDIRECT_TO_TGC is duplicated in kidsacademy/kidsacademy/worksheet-check/src/utils/utils.ts:77
  OPEN_ACTIVITY_IN_TGC: 'ka_website.openActivityInTgc',
  WORKSHEET_ID: 'ka_website.worksheetId',
  LESSON_ID: 'ka_website.lessonId',
  REDIRECT_TO_TGC: 'ka_website.toTGC',
  SHOW_TGC_ONBOARDING: 'ka_website.showOnboarding',
  AD_FREE_MODAL: 'adsFreeModal',
  FROM_DISTRIBUTOR_SIGN_UP_FLOW: 'distributorCodeFlow',
  EMAIL: 'email',
  ACCOUNT_TYPE: 'type',
  MAKE_REDIRECT: 'makeRedirect',
  COUPON: 'coupon',
  GRADE: 'grade',
  SUBJECT: 'subject',
  SKIP_ONBOARDING: 'skipOnboarding',
  ERROR: 'error',
  NEXT: 'next',
  PLAN_TO_UPGRADE_SLUG: 'planToUpgradeSlug',
  MESSAGE_ID: 'messageId',
  TITLE: 'title',
  IS_TGC: 'isTGC',
  VERSION: 'v',
  GAME_FULL_SCREEN_MODE: 'fullScreen',
  DOWNLOAD_POPUP_LIMIT: 'downloadLimitPopup',
  CAMPAIGN_ID: 'CampaignID'
};

export const DEFAULT_GRADE_ID_FOR_TGC = 1;

export const LOCAL_STORAGE_KEYS = {
  WORKSHEET_LAST_DOWNLOAD_DATE: 'worksheetLastDownloadDate',
  WORKSHEET_TRY_DOWNLOADED_COUNT: 'worksheetTryDownloadedCount',
  WORKSHEET_ID: 'worksheetId',
  WORKSHEET_SLUG: 'worksheetSlug',
  WORKSHEET_TO_DOWNLOAD_DATA: 'worksheetToDownloadData',
  WORKSHEET_ACTION: 'worksheetsAction',
  PLACEMENT: 'placement',
  WEBSITE_ACCOUNT_TYPE: 'websiteAccountType',
  PARTNER_COUPON_CODE: 'partnerCouponCode',
  IS_UI_TESTING_MODE: 'isTestRunning',
  IS_SOUND_MUTED: 'isSoundMuted',
  IS_BLOG_SUBSCRIBED: 'isBlogSubscribed',
  POPUP_LAST_DISPLAY_DATE: 'lastPopupDisplayDate',
  SUBSCRIPTION_POPUP_DISPLAY_COUNTER: 'subscriptionPopupDisplayCounter',
  IS_SHOW_STEP_PROGRESS_BAR: 'isShowStepProgressBar',
  SHOW_WEBSITE_PLANS_MODAL: 'showWebsitePlansModal',
  SHOW_WEBSITE_PLANS_MODAL_PURCHASE: 'showWebsitePlansModalPurchase',
  SHOW_PROMO_PLANS_MODAL_PURCHASE: 'showPromoPlansModalPurchase',
  WEBSITE_PLANS_MODAL_PLAN_SLUG: 'websitePlansModalPlanSlug',
  WEBSITE_PLANS_MODAL_EBLOX_ADDED: 'websitePlansModalEbloxAdded',
  CHECK_AVAILABILITY_OF_PDF: 'checkAvailabilityOfPdf',
  JOIN_KIDSACADEMY_FOR_FREE: 'joinKidsAcademyForFree'
};

export const JOIN_KIDSACADEMY_FOR_FREE_MODAL_TYPE = {
  GAME: 'GAME',
  COMPLETE_ONLINE: 'COMPLETE_ONLINE',
  DEFAULT: 'DEFAULT'
};

export const SUBSCRIPTION_TYPES = {
  PARENT: '1',
  SCHOOL: '2',
  DISTRICT: '3'
};

export const FEEDBACK_TYPES = {
  REQUEST: 1,
  SCHOOL_REQUEST: 2,
  ISSUE_REPORT: 3,
  QUESTION_FOR_EXPERT: 4,
  QUOTE_REQUEST: 5,
  // CONTENT_LICENSING: 6, need to add this to back-end
};

export const CURRENCY = '$';

export const SUPPORT_EMAIL = 'accounts@kidsacademy.com';

export const ERROR_CODES = {
  CAPTCHA_INVALID:    1400,
  ALREADY_REGISTERED: 1110,
  HAS_NO_SCHOOL_DISTRICT: 1104,
  AUTH_STUDENT_USER: 1119,
  // eslint-disable-next-line max-len
  STUDENT_LIMIT_EXCEEDED: 1601  // max students already joined classroom (classroom was created by teacher without subscription)
};

export const RESPONSE_CODES = {
  1101: 'INVALID_DISTRICT',
  1102: 'INVALID_SCHOOL',
  1103: 'INVALID_USER',
  1104: 'EMPTY_USER',
  1119: 'AUTH_STUDENT_USER',
  900:  'GIFT_CODE_INVALID',
  1008: 'SUBSCRIPTION_EXISTS',
  1117: 'INVALID_ACCOUNT_TYPE'
};

export const SUBJECTS = {
  INVALID_DISTRICT: 'District is not validated',
  INVALID_SCHOOL: 'School is not validated',
  INVALID_USER: 'User is not validated',
  EMPTY_USER: 'User has no school and district',
  GIFT_CODE_INVALID: '',
  SUBSCRIPTION_EXISTS: '',
  INVALID_ACCOUNT_TYPE: ''
};

export const ACCOUNT_TYPES = {
  PARENT: '0',
  TEACHER: '1',
  SCHOOL_ADMIN: '3',
  DISTRICT: '2',
  KID: '6'
};

export const ACCOUNT_TYPES_STRING = {
  PARENT: 'parent',
  TEACHER: 'teacher',
  DISTRICT: 'district'
};

export const SUBJECTS_CONFIG = {
  /* eslint-disable max-len */
  prek:   [{name: 'Math', id: 1}, {name: 'ELA', id: 2}, {name: 'The World Around Us', id: 8}, {name: 'Chess', id: 6}, {name: 'Art and Crafts', id: 9}],
  gradek: [{name: 'Math', id: 1}, {name: 'ELA', id: 2}, {name: 'Science', id: 3}, {name: 'Reading', id: 4}, {name: 'Social Studies', id: 5}, {name: 'Chess', id: 6}],
  grade1: [{name: 'Math', id: 1}, {name: 'ELA', id: 2}, {name: 'Science', id: 3}, {name: 'Reading', id: 4}, {name: 'Social Studies', id: 5}, {name: 'Chess', id: 6}],
  grade2: [{name: 'Math', id: 1}, {name: 'ELA', id: 2}, {name: 'Science', id: 3}, {name: 'Social Studies', id: 5}, {name: 'Chess', id: 6}],
  grade3: [{name: 'Math', id: 1}, {name: 'ELA', id: 2}, {name: 'Science', id: 3}, {name: 'Social Studies', id: 5}, {name: 'Chess', id: 6}]
  /* eslint-enable */
};

export const ACCOUNT_SETTINGS_NAV_ITEM = {
  REPORT: 'report'
};

export const EBLOX_COUPONS = ['eblox', 'e-blox'];

export const PROMO_LANDINGS_URLS = [
  '/online-learning-app-games-video-practice/',
  '/1/',
  '/2/',
];

export const SUBJECTS_CATEGORIES_MAPPING = {
  'Math': ['Math', 'Logic and Early Math'],
  'English': ['English Language Arts', 'English as a Second Language'],
  'World Around Us': ['World around Us'],
  'Science': ['Science'],
  'Social Studies': ['Social Studies'],
  'Chess': ['Chess'],
  'Art And Crafts': ['Arts'],
  'Reading': ['Foundational Reading'],
};

export const SUBJECTS_GRADES_MAPPING = {
  'Math': [1, 2, 3, 5, 6],
  'English': [1, 2, 3, 5, 6],
  'World Around Us': [1],
  'Science': [2, 3, 5, 6],
  'Social Studies': [2, 3, 5, 6],
  'Chess': [1, 2, 3, 5, 6],
  'Art And Crafts': [1],
  'Reading': [2, 3, 5, 6],
};

// eslint-disable-next-line max-len
export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const SUBSCRIPTION_CANCEL_REASON_IDS = {
  OTHER: 3
};

export const IFRAME_EVENTS = {
 GAME_HINT_SHOWN: 'GAME_HINT_SHOWN',
 GAME_SCORE: 'GAME_SCORE',
 GAME_TIME_STARTED: 'GAME_TIME_STARTED',
 GAME_TIME_CANCELLED: 'GAME_TIME_CANCELLED',
 GAME_TIME_COMPLETED: 'GAME_TIME_COMPLETED',
};

export const POST_HOG = {
  PROJECT_ID: {
    CLASSROOM: 'phc_KHhKCtPwhypuRp8t03IpkA0KT20AzZ0skCK5a9xnnGs',
    TGC: 'phc_W2hlcR55zEcKOGNcD2VHQ2WKigsciqp5zNrH4ML4Zyx'
  }
};

export const IFRAME_POST_EVENTS = {
  WEB_TOGGLE_SOUNDS: 'WEB_TOGGLE_SOUNDS'
};

export const TGC_IFRAME_EVENTS = {
  OPEN_ACTIVITY:            'OPEN_ACTIVITY',
  GAME_INIT_STARTED:        'GAME_INIT_STARTED',
  GAME_INIT_COMPLETED:      'GAME_INIT_COMPLETED',
  GAME_SOUNDS_ACTIVATED:    'GAME_SOUNDS_ACTIVATED',
  PURCHASE_INIT:            'PURCHASE_INIT',
  PURCHASE_CANCELED:        'PURCHASE_CANCELED',
  PURCHASE_COMPLETED:       'PURCHASE_COMPLETED',
  USER_UPDATED:             'USER_UPDATED',
  USER_REQUEST_EMAIL:       'USER_REQUEST_EMAIL'
};

// eslint-disable-next-line max-len
export const DROPIN_SCRIPT_URL = 'https://js.braintreegateway.com/web/dropin/1.33.7/js/dropin.min.js';

export const CUSTOM_MESSAGE = {
  THREE_D_SECURE_ABORT: 'Purchase cancelled, please select another payment method',
  BRAINTREE_CREATE_ERROR: 'Please try again.',
};

// subscription popup modal options
export const SUBSCRIPTION_POPUP = {
  DEFAULT_DELAY: 10000,
  SECOND_DISPLAY_DELAY: 120000,
  DISPLAY_LIMIT: 2
};

// timeout to start download worksheet/show close popup btn
export const WORKSHEET_DOWNLOAD_DELAY = 10000;

// time limit for loading spinner on start download elements
export const WORKSHEET_POPUP_INIT_LIMIT = 15000;

// attribute representing monetization popup purchase step have been triggered
export const PURCHASE_STEP_TRIGGERED_ATTRIBUTE = 'data-purchase-triggered';

export const SUBSCRIPTION_PERIODS = {
  LIFETIME: 'lifetime'
};

export const SUBSCRIPTION_PERIOD_MAPPING = {
  /* eslint-disable max-len */
  'one_day':     (price) => `<p>Renews at ${CURRENCY}${price} / day</p>`,
  'half_yearly': (price) => `<p>Renews at ${CURRENCY}${price} / half year after free trial</p>`,
  'monthly':     (price) => `<p>Renews at ${CURRENCY}${price} / month after free trial</p>`,
  'quarterly':   (price) => `<p>Renews at ${CURRENCY}${price} / quarter after free trial</p>`,
  'yearly':      (price) => `<p>Renews at ${CURRENCY}${price} / year after free trial</p>`,
  'lifetime':    () => ''
  /* eslint-enable */
};

export const SECONDS_PER_DAY = 86400;
export const SECONDS_PER_HOUR = 3600;
export const SECONDS_PER_MINUTE = 60;

export const MAILING_LISTS = {
  BLOG_POPUP: 'kidsacademy_newsletter',
  TGC_LIST: 'kidsacademy_tgc_list'
};

export const MODALS = {
  FREE_SIGN_UP_FOR_DOWNLOAD_W:           '#freeSignupForDownloadWorksheet',
  ACCOUNT_TYPE_MODAL:                    '#websiteAccountTypeModal',
  WEBSITE_SIGN_UP_MODAL:                 '#learning-resources-registration',
  WEBSITE_QUESTION_FAVOURITES_MODAL:     '#website-question-favourites-modal',
  WEBSITE_PLANS_MODAL:                   '#website-plans-modal',
  WEBSITE_CR_TG_PLANS_MODAL:             '#website-cr-tg-plans-modal',
  WEBSITE_QUESTION_DONE_MODAL:           '#website-question-done-modal',
  MONETIZATION:                          '#monetization-popup',
  ADDS_FREE:                             '#addFreeModal',
  WORKSHEET_IMAGE_POPUP:                 '#worksheetImagePopup',
  WORKSHEET_IMAGE_POPUP_ID:              (id) => `#worksheetImagePopup-${id}`,
  QUIZ_IMAGE_POPUP:                      '#quizImagePopup',
  TGC_SUBSCRIPTION_POPUP:                '#tgcSubscriptionPopUp',
  WEB_TGC_PAYWALL_POPUP:                 '#tgc-paywall-modal',
  WEB_TGC_LOGIN_MODAL:                   '#tgcLogInModal',
  WEB_TGC_RESET_PASSWORD_MODAL:          '#tgcResetPasswordModal',
  TGC_PAYWALL_MODAL_NEW:                 '#tgc-paywall-modal-new',
  JOIN_KA_FOR_FREE_MODAL:                '#join-ka-for-free-modal',
  SUBSCRIPTION_PROMO_MODAL:              '#subscriptionPromoModal'
};

export const WORKSHEET_ACTIONS = {
  GO_TO_WORKSHEET_CHECK_IN_TGC: 'goToWorksheetCheckInTgc',
  ADD_TO_FAVORITE:              'addToFavorite',
  DELETE_FROM_FAVORITE:         'deleteFromFavorite',
  MARK_AS_DONE:                 'markAsDone',
  UNMARK_AS_DONE:               'unmarkAsDone',
  FAVORITE_FILTER:              'favoriteFilter',
  DONE_FILTER:                  'doneFilter',
  INIT_WORKSHEET_DOWNLOAD:      'initDownload'
};

export const PLACEMENTS = {
  WORKSHEET_LIST_PAGE:   'worksheet-list-page',
  WORKSHEET_DETAIL_PAGE: 'worksheet-page',
  LESSON_DETAIL_PAGE:    'worksheet-lesson-page'
};

export const WEBSITE_CUSTOM_EVENTS = {
  DOWNLOAD_WORKSHEET: 'downloadWorksheet',
  SHOW_PLANS_POPUP_NO_FREE: 'showPlansPopupNoFree',
  RELOAD_HTMX_ADS_BANNERS: 'reloadHtmxAdsBanners'
};

export const WEBSITE_LIMIT_STATUSES = {
  ALLOWED: 'ALLOWED',
  SHOW_PROMO_BANNER: 'SHOW_PROMO_BANNER',
  DENIED: 'DENIED'
};

export const URL_NAMES = {
  WORKSHEET_ANSWERS: 'worksheet-answers'
};

export const LANGUAGE_CODES = {
  SPANISH: 'es',
  ENGLISH: 'en'
};

export const MIDDLE_WIDTH = 991;

// export const MOBILE_WIDTH = 767;

// export const SHOW_ADDS_FREE_MODAL_DELAY = 1000;


export const UTM = {
  SOURCE: {
    MOBI: 'kidsacademy-mobi',
    COM: 'kidsacademy-com'
  },
  MEDIUM: {
    VIDEO: 'video',
    QUIZ: 'quiz',
    WORKSHEET: 'worksheet',
    GAME: 'game',
    EDUCATORS: 'educators'
  },
  CAMPAIGN: {
    LANDING: 'landing'
  },
  CONTENT: {
    CATALOG: 'catalog',
    ACTIVITY_PAGE: 'activity+page',
    START_CLASSROOM: 'start+my+classroom'
  }
};

/* web tgc onboarding events: /tg */
export const POST_HOG_PAGES = {
  SUBSCRIPTION: 'Subscription',
  ONBOARDING: 'Onboarding',
};

/* web tgc onboarding events: /tg */
export const POST_HOG_EVENTS = {
  TRIAL: 'Trial',
  SIGN_IN: 'Sign in',
  PURCHASE: 'Purchase',
  START_TRIAL_BTN: 'Start trial button',
  PURCHASE_ERROR: 'Purchase error',
  TGC_STEP_1: 'tgc step 1',
  TGC_STEP_2: 'tgc step 2',
  TGC_STEP_3: 'tgc step 3',
  PAYWALL: 'Paywall',
  SIGN_UP_OK: 'Sign up ok',
  SIGN_UP_ERROR: 'Sign up error',
};

export const HTTPS_PREFIX = () => {
  let prefix = 'https://'; // prod & stage

  const env = window.ENV ? window.ENV : window.boot.ENV;
  if (env === 'dev') prefix = 'http://';
  if (!env ) prefix = 'https://';

  return prefix;
};

export const WORKSHEET_ACTION_TYPES = {
  COMPLETE_ONLINE: 'completeOnline',
  DOWNLOAD: 'download',
  LEARN_ONLINE: 'learnOnline',
};

export const IS_DOWNLOAD_WORKSHEET_FLOW = '_download-worksheet-flow';
